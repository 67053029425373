<template>
  <div class="stepsContent">
    <div
      v-for="(item, index) in content"
      :key="index"
      :class="{ on: index <= active, borderBefore: index < active }"
    >
      <div class="icon-name">
        <p class="icon" v-if="index !== active"></p>
        <van-icon v-else name="checked" color="#07c160" />
      </div>
      <span class="space-text">
        {{ item }}
      </span>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import { Icon } from 'vant'
export default {
  name: 'StepsAuto',
  props: {
    active: {
      type: Number,
      required: true,
      default: 0,
    },// 第几级
    content: {
      type: Array,
      required: true,
      default: () => [],
    }, // 需要展示
  },
  components: {
    [Icon.name]: Icon,
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  watch: {},
  created() {},
  methods: {},
}
</script>
<style lang="less" scoped>
.stepsContent {
  width: 100%;
  height: calc(90rem / 16);
  display: flex;
  font-size: calc(12rem / 16);
  align-items: center;
  justify-content: center;
  padding: calc(10rem / 16) 0 0;
  > div {
    width: 20%;
    height: calc(90rem / 16);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #c0c4cc;
    > .icon-name {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(5rem / 16);
      > p {
        width: calc(5rem / 16);
        height: calc(5rem / 16);
        display: flex;
        align-items: center;
        justify-content: center;
        // border: calc(1rem / 16) solid #c0c4cc;
        border-radius: 50px;
        background: #c0c4cc;
      }
    }

    > span {
      text-align: center;
      margin-top: calc(8rem / 16);
    }
    &.on {
      // color: #07c160;
      color: #323233;
      > .icon-name {
        > p {
          background: #07c160;
        }
        // border: calc(1rem / 16) solid #07c160;
      }
    }
  }
  > div::before {
    content: '';
    width: 70%;
    height: calc(1rem / 16);
    background: #c0c4cc;
    display: block;
    position: relative;
  }
  > div:nth-last-child(1)::before {
    width: 0;
  }
  > .borderBefore::before {
    content: '';
    width: 70%;
    height: calc(1rem / 16);
    background: #07c160;
    display: block;
    position: relative;
  }
  // > div::after {
  //   content: '';
  //   width: 50%;
  //   height: calc(1rem / 16);
  //   background: #000;
  //   display: block;
  //   position: relative;
  // }
  /* left */
  > div::before {
    top: calc(4rem / 16);
    // left: calc(33rem / 16);
    left: 50%;
  }
  /* right */
  // > div::after {
  //   top: -15rpx;
  //   right: -570rpx;
  // }
}
</style>
